import ApiClient from './ApiClient';

const hotelClient = new ApiClient('hotels');
const accountHotelClient = new ApiClient('hotel', { accountScoped: true });

const HotelAPI = {
  getAll() {
    return hotelClient.get();
  },
  getForAccount(accountId) {
    // Nota: non abbiamo bisogno di passare accountId qui perché
    // ApiClient lo prenderà automaticamente dall'URL
    return accountHotelClient.get();
  },
  show(id) {
    return hotelClient.show(id);
  },
  create(data) {
    return hotelClient.create(data);
  },
  update(id, data) {
    return hotelClient.update(id, data);
  },
  delete(id) {
    return hotelClient.delete(id);
  }
};

export default HotelAPI;