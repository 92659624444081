import Vue from 'vue';
import types from '../../mutation-types';
import axios from 'axios';
import TemplateConverter from '../../../services/TemplateConverter';

const state = {
  blocks: [],
  selectedBlockId: null,
  editingBlockId: null,
  isDragging: false,
  isEditing: false,
  isAnyBlockResizing: false,
  draggedBlockType: null,
  previewImage: null,
  history: {
    past: [],
    future: []
  },
  templates: [],
  layoutMode: '1d',
  blockPositions: {},
  layoutSettings: {
    backgroundColor: '#ffffff'
  }
};

export const mutations = {
  [types.SET_EDITOR_BLOCKS](_state, blocks) {
    console.log('🟥 MUTATION: SET_EDITOR_BLOCKS', {
      currentBlocks: _state.blocks,
      newBlocks: blocks
    });
    _state.blocks = [...blocks];
  },
  [types.SET_EDITOR_SELECTED_BLOCK](_state, id) {
    console.log('🟥 MUTATION: SET_EDITOR_SELECTED_BLOCK', {
      currentId: _state.selectedBlockId,
      newId: id
    });
    _state.selectedBlockId = id;
  },
  [types.SET_EDITOR_DRAGGING](_state, isDragging) {
    _state.isDragging = isDragging;
  },
  [types.SET_EDITOR_EDITING](_state, isEditing) {
    _state.isEditing = isEditing;
  },
  [types.ADD_EDITOR_HISTORY](_state, blocks) {
    _state.history.past.push([...blocks]);
    _state.history.future = [];
  },
  [types.SET_EDITOR_EDITING_BLOCK](_state, id) {
    _state.editingBlockId = id;
  },
  [types.UPDATE_EDITOR_BLOCK](_state, { id, changes }) {
    console.log('🟥 Store Mutation - UPDATE_EDITOR_BLOCK:', {
      id,
      changes,
      currentBlock: _state.blocks.find(b => b.id === id)
    });

    const updateBlocksRecursively = (blocks) => {
      return blocks.map(block => {
        if (block.id === id) {
          const updatedBlock = { ...block, ...changes };
          console.log('🟥 Store Mutation - Block Updated:', {
            before: block,
            after: updatedBlock
          });
          console.log('🟥 Store Mutation - Stato completo dopo update:', _state.blocks);
          return updatedBlock;
        }
        
        if (block.type === 'row' && block.content?.columns) {
          return {
            ...block,
            content: {
              ...block.content,
              columns: block.content.columns.map(column => ({
                ...column,
                content: {
                  ...column.content,
                  blocks: updateBlocksRecursively(column.content.blocks || [])
                }
              }))
            }
          };
        }
        
        return block;
      });
    };

    const updatedBlocks = updateBlocksRecursively(_state.blocks);
    Vue.set(_state, 'blocks', updatedBlocks);
  },
  [types.SET_EDITOR_DRAGGED_BLOCK_TYPE](_state, type) {
    _state.draggedBlockType = type;
  },

  // Nuova mutazione per aggiungere un blocco a una colonna specifica
  [types.ADD_BLOCK_TO_COLUMN](_state, { columnId, block }) {
    const addBlock = (blocks) => {
      return blocks.map(blockItem => {
        if (blockItem.type === 'row' && blockItem.content?.columns) {
          const updatedColumns = blockItem.content.columns.map(column => {
            if (column.id === columnId) {
              return {
                ...column,
                content: {
                  ...column.content,
                  blocks: [...(column.content.blocks || []), block]
                }
              };
            }
            return column;
          });
          return {
            ...blockItem,
            content: {
              ...blockItem.content,
              columns: updatedColumns
            }
          };
        }
        return blockItem;
      });
    };

    const updatedBlocks = addBlock(_state.blocks);
    _state.blocks = updatedBlocks;
  },

  [types.DELETE_BLOCK](_state, blockId) {
    const deleteBlockRecursively = (blocks) => {
      return blocks.filter(block => {
        // Se è un blocco row, cerca nelle colonne
        if (block.type === 'row' && block.content?.columns) {
          return {
            ...block,
            content: {
              ...block.content,
              columns: block.content.columns.map(column => ({
                ...column,
                content: {
                  ...column.content,
                  blocks: deleteBlockRecursively(column.content.blocks || [])
                }
              }))
            }
          };
        }
        // Filtra via il blocco con l'ID corrispondente
        return block.id !== blockId;
      });
    };

    const updatedBlocks = deleteBlockRecursively(_state.blocks);
    _state.blocks = updatedBlocks;
  },
  [types.SET_EDITOR_TEMPLATES](state, templates) {
    state.templates = templates;
  },
  [types.REMOVE_TEMPLATE](state, templateId) {
    state.templates = state.templates.filter(t => t.id !== templateId);
  },
  SET_BLOCK_RESIZING(_state, value) {
    _state.isAnyBlockResizing = value;
  },
  [types.SET_EDITOR_LAYOUT_MODE](_state, mode) {
    _state.layoutMode = mode;
  },
  [types.SET_BLOCK_POSITION](_state, { blockId, position }) {
    Vue.set(_state.blockPositions, blockId, position);
  },
  [types.UPDATE_COLUMN](_state, { columnId, updates }) {
    const block = _state.blocks.find(b => 
      b.content?.columns?.some(col => col.id === columnId)
    );
    
    if (block) {
      const columnIndex = block.content.columns
        .findIndex(col => col.id === columnId);
      
      if (columnIndex !== -1) {
        Vue.set(block.content.columns, columnIndex, updates);
      }
    }
  },
  SET_PREVIEW_IMAGE(_state, image) {
    console.log('🟦 Store - Mutation SET_PREVIEW_IMAGE chiamata');
    try {
      _state.previewImage = image;
      console.log('🟦 Store - Immagine salvata con successo');
    } catch (error) {
      console.error('🔴 Store - Errore nella mutation:', error);
      throw error;
    }
  },
  [types.RESET_EDITOR_BLOCKS](_state) {
    _state.blocks = [];
    _state.selectedBlockId = null;
    _state.editingBlockId = null;
    _state.isDragging = false;
    _state.isEditing = false;
    _state.isAnyBlockResizing = false;
    _state.draggedBlockType = null;
    _state.history = {
      past: [],
      future: []
    };
  },
  [types.UPDATE_LAYOUT_SETTINGS](_state, settings) {
    _state.layoutSettings = {
      ..._state.layoutSettings,
      ...settings
    };
  },
};

const actions = {
  addBlock({ commit, state }, { type, isLayout, block }) {
    const currentBlocks = [...state.blocks];
    
    const newBlock = {
      ...block,
      section: block.section || 'content',
      height: block.height || 200, // Imposta un'altezza predefinita
      width: block.width || '100%', // Imposta una larghezza predefinita
    };
    
    const updatedBlocks = [...currentBlocks, newBlock];
    
    commit(types.SET_EDITOR_BLOCKS, updatedBlocks);
    commit(types.ADD_EDITOR_HISTORY, updatedBlocks);
    
    return newBlock;
  },

  updateBlocks({ commit, state, dispatch }, blocks) {
    console.log('🟥 ACTION: updateBlocks', {
      currentBlocks: state.blocks,
      newBlocks: blocks,
      isDifferent: blocks.length !== state.blocks.length
    });
    
    if (blocks.length === state.blocks.length + 1) {
      const newBlock = blocks[blocks.length - 1];
      console.log('🟥 Dispatching addBlock', {
        newBlock
      });
      return dispatch('addBlock', { 
        type: newBlock.type, 
        block: newBlock 
      });
    }
    
    commit(types.SET_EDITOR_BLOCKS, blocks);
    commit(types.ADD_EDITOR_HISTORY, blocks);
  },

  selectBlock({ commit }, id) {
    commit(types.SET_EDITOR_SELECTED_BLOCK, id);
  },
  setDragging({ commit }, isDragging) {
    commit(types.SET_EDITOR_DRAGGING, isDragging);
  },
  startEditing({ commit }, id) {
    console.log('🟦 Store - startEditing:', { id });
    commit(types.SET_EDITOR_EDITING_BLOCK, id);
    commit(types.SET_EDITOR_EDITING, true);
  },
  stopEditing({ commit }) {
    console.log('🟦 Store - stopEditing');
    commit(types.SET_EDITOR_EDITING_BLOCK, null);
    commit(types.SET_EDITOR_EDITING, false);
  },
  updateBlock({ commit, state }, payload) {
    console.log('🟦 Store Action - updateBlock:', {
      payload,
      currentBlock: state.blocks.find(b => b.id === payload.id),
      selectedBlockId: state.selectedBlockId
    });
    
    commit(types.UPDATE_EDITOR_BLOCK, payload);
    
    console.log('🟦 Store Action - After Update:', {
      updatedBlock: state.blocks.find(b => b.id === payload.id)
    });
  },
  setDraggedBlockType({ commit }, type) {
    commit(types.SET_EDITOR_DRAGGED_BLOCK_TYPE, type);
  },
  setEditing({ commit }, { blockId, isEditing }) {
    commit(types.SET_EDITOR_EDITING, isEditing);
    commit(types.SET_EDITOR_EDITING_BLOCK, blockId);
  },

  // Nuova azione per aggiungere un blocco a una colonna specifica
  addBlockToColumn({ commit, state }, { columnId, block, parentId }) {
    // Aggiorna la struttura gerarchica
    const updatedBlock = {
      ...block,
      parentId,
      columnId
    };

    commit(types.ADD_BLOCK, updatedBlock);
    return updatedBlock;
  },

  deleteBlock({ commit, state }, blockId) {
    commit(types.DELETE_BLOCK, blockId);
    commit(types.ADD_EDITOR_HISTORY, state.blocks);
    
    return true;
  },

  // ... x il Salvataggio del Template 
  
  async saveTemplate({ state, rootState, rootGetters }, templateData) {
    try {
      const accountId = templateData.account_id || 
                       rootState.accounts.records?.[0]?.id ||
                       window.localStorage.getItem('account_id');

      if (!accountId) {
        throw new Error('Account ID non trovato');
      }

      const response = await axios.post(
        `/api/v1/accounts/${accountId}/editor_templates`,
        {
          editor_template: {
            ...templateData,
            account_id: accountId
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
          }
        }
      );
      
      return response.data;
    } catch (error) {
      console.error('Save template error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      throw error;
    }
  },

  async fetchTemplates({ rootState, commit }) {
    try {
      const accountId = rootState.accounts.records?.[0]?.id || 
                       window.localStorage.getItem('account_id');

      if (!accountId) {
        throw new Error('Account ID non trovato');
      }

      const response = await axios.get(
        `/api/v1/accounts/${accountId}/editor_templates`
      );
      commit(types.SET_EDITOR_TEMPLATES, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async deleteTemplate({ rootState, commit }, templateId) {
    try {
      const accountId = rootState.accounts.records?.[0]?.id || 
                       window.localStorage.getItem('account_id');

      if (!accountId) {
        throw new Error('Account ID non trovato');
      }

      await axios.delete(
        `/api/v1/accounts/${accountId}/editor_templates/${templateId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
          }
        }
      );
      
      commit(types.REMOVE_TEMPLATE, templateId);
    } catch (error) {
      console.error('Delete template error:', error);
      throw new Error(this.$t('Impossibile eliminare il template'));
    }
  },

  async duplicateTemplate({ rootState, dispatch }, templateId) {
    try {
      const accountId = rootState.accounts.records?.[0]?.id || 
                       window.localStorage.getItem('account_id');

      if (!accountId) {
        throw new Error('Account ID non trovato');
      }

      await axios.post(
        `/api/v1/accounts/${accountId}/editor_templates/${templateId}/duplicate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
          }
        }
      );
      
      return await dispatch('fetchTemplates');
    } catch (error) {
      console.error('Duplicate template error:', error);
      throw error;
    }
  },

  async loadTemplate({ rootState, commit }, templateId) {
    try {
      const accountId = rootState.accounts.records?.[0]?.id || 
                       window.localStorage.getItem('account_id');

      if (!accountId) {
        console.log('🔵 EditorStore: Account state:', {
          stateAccountId: rootState.accounts.records?.[0]?.id,
          localStorageAccountId: window.localStorage.getItem('account_id')
        });
        throw new Error('Account ID non trovato');
      }

      const response = await axios.get(
        `/api/v1/accounts/${accountId}/editor_templates/${templateId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
          }
        }
      );
      
      commit(types.SET_EDITOR_BLOCKS, response.data.content);
      return response.data;
    } catch (error) {
      console.error('Load template error:', error);
      throw error;
    }
  },

  setBlockResizing({ commit }, value) {
    commit('SET_BLOCK_RESIZING', value);
  },

  selectBlock({ commit }, blockId) {
    console.log('🟦 Store - selectBlock:', {
      blockId,
      currentState: state.selectedBlockId
    });
    commit(types.SET_EDITOR_SELECTED_BLOCK, blockId);
  },

  async handleInsert({ commit, state }, blocks) {
    try {
      console.log('🟦 MODAL:', JSON.stringify(blocks, null, 2));
      const cleanBlocks = JSON.parse(JSON.stringify(blocks));
      console.log('🔍 MODAL (dopo pulizia):', JSON.stringify(cleanBlocks, null, 2));
      
      // Convertiamo i blocchi in formato email usando TemplateConverter
      const convertedBlocks = TemplateConverter.toEmailFormat(cleanBlocks, state.layoutSettings);
      
      return {
        blocks: cleanBlocks,
        convertedBlocks
      };
    } catch (error) {
      console.error('🔴 EditorModal - Errore in handleInsert:', error);
      throw error;
    }
  },

  setPreviewImage({ commit }, image) {
    console.log('🟦 Store - Action setPreviewImage chiamata');
    try {
      if (!image) {
        throw new Error('Immagine non valida');
      }
      commit('SET_PREVIEW_IMAGE', image);
      return true;
    } catch (error) {
      console.error('🔴 Store - Errore durante il salvataggio immagine:', error);
      throw error;
    }
  },
  resetBlocks({ commit }) {
    commit(types.RESET_EDITOR_BLOCKS);
  },
  updateLayoutSettings({ commit }, settings) {
    commit(types.UPDATE_LAYOUT_SETTINGS, settings);
  },
};

const getters = {
  blocks: state => state.blocks,
  selectedBlockId: state => state.selectedBlockId,
  editingBlockId: state => state.editingBlockId,
  selectedBlock: state => state.blocks.find(block => block.id === state.selectedBlockId),
  contentBlocks: state => {
    return state.blocks.filter(block => {
      return block.section === 'content' || block.type === 'row';
    });
  },
  canUndo: state => state.history.past.length > 0,
  canRedo: state => state.history.future.length > 0,
  templates: state => state.templates,
  isEditing: state => state.isEditing,
  isAnyBlockResizing: state => state.isAnyBlockResizing,
  layoutMode: state => state.layoutMode,
  blockPosition: state => blockId => state.blockPositions[blockId] || null,
  previewImage: state => state.previewImage,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};