class TemplateConverter {
  static toEmailFormat(blocks, layoutSettings = {}) {
    const backgroundColor = layoutSettings?.backgroundColor || '#ffffff';
    
    return blocks.map(block => {
      return {
        ...block,
        emailVersion: this.convertBlock(block, backgroundColor)
      };
    });
  }

  static convertBlock(block, backgroundColor) {
    switch (block.type) {
      case 'text':
        return this.convertTextBlock(block, backgroundColor);
      case 'image':
        return this.convertImageBlock(block, backgroundColor);
      case 'button':
        return this.convertButtonBlock(block, backgroundColor);
      case 'spacer':
        return this.convertSpacerBlock(block, backgroundColor);
      case 'divider':
        return this.convertDividerBlock(block, backgroundColor);
      default:
        return this.convertDefaultBlock(block, backgroundColor);
    }
  }

  static convertTextBlock(block, backgroundColor) {
    const styles = {
      'font-family': block.styles.fontFamily || 'Arial',
      'font-size': block.styles.fontSize || '14px',
      'color': block.styles.color || '#000000',
      'background-color': backgroundColor,
      'padding': '0',
      'margin': '0'
    };

    return {
      type: 'text',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td align="${block.alignment || 'left'}" 
                style="${this.convertStylesToString(styles)}">
              ${block.content.text}
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertImageBlock(block, backgroundColor) {
    const tdStyles = {
      'padding': '0',
      'margin': '0'
    };

    const imgStyles = {
      'display': 'block',
      'width': block.styles.width || '100%',
      'max-width': block.styles.maxWidth || '600px',
      'margin': block.alignment === 'right' ? '0 0 0 auto' : 
                block.alignment === 'center' ? '0 auto' : '0'
    };

    return {
      type: 'image',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td align="${block.alignment || 'left'}" style="${this.convertStylesToString(tdStyles)}">
              <img src="${block.content.url}"
                   alt="${block.content.alt || ''}"
                   style="${this.convertStylesToString(imgStyles)}"
                   width="${block.styles.width || '100%'}"
              />
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertButtonBlock(block, backgroundColor) {
    const buttonStyles = {
      'font-family': 'Arial, sans-serif',
      'font-size': '14px',
      'color': block.styles.color || '#ffffff',
      'background-color': block.styles.backgroundColor || '#D9D9D9',
      'padding': block.styles.padding || '10px 20px',
      'text-decoration': 'none',
      'border-radius': block.styles.borderRadius || '4px',
      'display': 'inline-block',
      'text-align': 'center',
      'width': '100%'
    };

    const tableWidth = block.styles.width || '100%';

    return {
      type: 'button',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td align="${block.alignment || 'left'}" style="padding: 10px 0;">
              <table cellpadding="0" cellspacing="0" border="0" width="${tableWidth}" style="width: ${tableWidth};">
                <tr>
                  <td align="center">
                    <a href="${block.content.url || '#'}" 
                       target="_blank"
                       style="${this.convertStylesToString(buttonStyles)}">
                      ${block.content.text || 'Click here'}
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertDividerBlock(block, backgroundColor) {
    const dividerStyles = {
      'border': 'none',
      'border-top': `${block.content.thickness}px ${block.content.style} ${block.content.color}`,
      'width': block.styles.width || '100%',
      'margin': '0',
      'padding': '0'
    };

    return {
      type: 'divider',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td align="${block.alignment || 'left'}" style="padding: 20px 0;">
              <div style="${this.convertStylesToString(dividerStyles)}"></div>
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertSpacerBlock(block, backgroundColor) {
    const height = block.content.height || '20px';
    
    return {
      type: 'spacer',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td height="${height}" style="
              font-size: 0;
              line-height: ${height};
              mso-line-height-rule: exactly;
              padding: 0;
              margin: 0;
              height: ${height};
            ">
              &nbsp;
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertDefaultBlock(block, backgroundColor) {
    return {
      type: block.type || 'unknown',
      content: block.content,
      emailHTML: `
        <table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
          <tr>
            <td style="padding: 10px;">
              <div style="padding: 10px; border: 0px solid #ccc;">
                <p>Tipo di blocco non supportato: ${block.type}</p>
                <pre>${JSON.stringify(block.content, null, 2)}</pre>
              </div>
            </td>
          </tr>
        </table>
      `
    };
  }

  static convertStylesToString(styles) {
    return Object.entries(styles)
      .map(([key, value]) => `${key}: ${value}`)
      .join('; ');
  }
}

export default TemplateConverter; 