import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({ accountLabels: 'labels/getLabels' }),
    savedLabels() {
      if (this.conversationLabels) {
        return this.conversationLabels.split(',').map(item => parseInt(item.trim(), 10));
      }
      return this.$store.getters['conversationLabels/getConversationLabels'](
        this.conversationId
      );
    },
    activeLabels() {
      return this.accountLabels.filter(label => 
        this.savedLabels.map(Number).includes(label.id)
      );
    },
    inactiveLabels() {
      return this.accountLabels.filter(
        label => !this.savedLabels.map(Number).includes(label.id)
      );
    },
  },
  methods: {
    addLabelToConversation(value) {
      const result = this.activeLabels.map(item => item.id);
      result.push(value.id);
      this.onUpdateLabels(result);
    },
    removeLabelFromConversation(value) {
      const result = this.activeLabels
        .map(label => label.id)
        .filter(labelId => labelId !== value);
      this.onUpdateLabels(result);
    },
    async onUpdateLabels(selectedLabels) {
      this.$store.dispatch('conversationLabels/update', {
        conversationId: this.conversationId,
        labels: selectedLabels,
      });
    },
  },
};
