<template>
  <div class="context-menu">
    <!-- Confirm Deletion -->
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('LABEL_MGMT.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <!-- Button to open the menu -->
    <woot-button
      icon="more-vertical"
      color-scheme="secondary"
      variant="clear"
      size="small"
      @click.stop.prevent="handleOpen"
    />

    <!-- Actual menu -->
    <woot-context-menu
      v-if="isOpen"
      :x="contextMenuPosition.x"
      :y="contextMenuPosition.y"
      @close="handleClose"
    >
      <div class="menu-container">
        <menu-item
          v-if="enabledOptions['create']"
          :option="{
            icon: 'add-circle',
            label: $t('Crea sotto-etichetta'),
          }"
          color-scheme="success"
          class-names="button--fixed-top"
          variant="icon"
          @click="openAddPopup"
        />
        <menu-item
          v-if="enabledOptions['edit']"
          :option="{
            icon: 'edit',
            label: $t('Modifica'),
          }"
          color-scheme="secondary"
          class-names="grey-btn"
          variant="icon"
          @click="openEditPopup"
        />
        <menu-item
          v-if="enabledOptions['delete']"
          :option="{
            icon: 'dismiss-circle',
            label: $t('Elimina'),
          }"
          color-scheme="alert"
          class-names="grey-btn"
          variant="icon"
          @click="openDeletePopup"
        />
      </div>
    </woot-context-menu>
  </div>
</template>
<script>
import TranslateModal from 'dashboard/components/widgets/conversation/bubble/TranslateModal.vue';
import AddCannedModal from 'dashboard/routes/dashboard/settings/canned/AddCanned.vue';
import alertMixin from 'shared/mixins/alertMixin';
import MenuItem from '../../../components/widgets/conversation/contextMenu/menuItem.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    AddCannedModal,
    TranslateModal,
    MenuItem,
  },
  mixins: [alertMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    enabledOptions: {
      type: Object,
      default: () => ({}),
    },
    contextMenuPosition: {
      type: Object,
      default: () => ({}),
    },
    labelObject: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loading: {},
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      records: 'labels/getLabels',
      uiFlags: 'labels/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    deleteConfirmText() {
      return this.$t('LABEL_MGMT.DELETE.CONFIRM.YES');
    },
    deleteRejectText() {
      return this.$t('LABEL_MGMT.DELETE.CONFIRM.NO');
    },
    deleteMessage() {
      return ` ${this.labelObject.title ?? ''}?`;
    },
  },
  mounted() {
    this.$store.dispatch('labels/get');
  },
  methods: {
    openAddPopup(e) {
      this.handleClose(e);
      this.$emit('add-label');
    },
    openEditPopup(e) {
      this.handleClose(e);
      this.$emit('edit-label');
    },
    openDeletePopup(e) {
      this.handleClose(e);
      this.showDeleteConfirmationPopup = true;
    },
    handleOpen(e) {
      this.$emit('open', e);
    },
    handleClose(e) {
      this.$emit('close', e);
    },
    closeDeletePopup(e) {
      if (e) {
        e.stopPropagation();
      }
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion(e) {
      this.loading[this.labelObject.id] = true;
      this.closeDeletePopup(e);
      this.deleteLabel(this.labelObject.id);
    },
    deleteLabel(id) {
      this.$store
        .dispatch('labels/delete', { id, accountId: this.accountId })
        .then(() => {
          this.showAlert(this.$t('LABEL_MGMT.DELETE.API.SUCCESS_MESSAGE'));
        })
        .catch(() => {
          this.showAlert(this.$t('LABEL_MGMT.DELETE.API.ERROR_MESSAGE'));
        })
        .finally(() => {
          this.loading[this.labelObject.id] = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  @apply p-1 bg-white dark:bg-slate-900 shadow-xl rounded-md;

  hr:first-child {
    @apply hidden;
  }

  hr {
    @apply m-1 border-b border-solid border-slate-50 dark:border-slate-800/50;
  }
}

.context-menu--delete-modal {
  ::v-deep {
    .modal-container {
      @apply max-w-[30rem];

      h2 {
        @apply font-medium text-base;
      }
    }

    .modal-footer {
      @apply pt-4 pb-8 px-8;
    }
  }
}
</style>
