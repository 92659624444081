<template>
    <div class="whatsapp-button">
      <woot-button
        @click="openWhatsApp"
        class="whatsapp-icon"
        color-scheme="success"
        size="small"
        :icon="icon"
      >
        {{ buttonText }}
      </woot-button>
    </div>
  </template>
  
  <script>
  import WootButton from './WootButton.vue';
  
  export default {
    name: 'WhatsAppButton',
    components: {
      WootButton,
    },
    props: {
      phoneNumber: {
        type: String,
        required: true
      },
      buttonText: {
        type: String,
        default: 'Contattaci su WhatsApp'
      },
      message: {
        type: String,
        default: 'Ciao, ho una domanda riguardo Otello AI.'
      },
      icon: {
        type: String,
        default: 'brand-whatsapp'
      }
    },
    methods: {
      openWhatsApp() {
        const encodedMessage = encodeURIComponent(this.message);
        const whatsappUrl = `https://wa.me/${this.phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
      }
    }
  }
  </script>
  
  <style scoped>
  .whatsapp-button {
    display: inline-block;
  }
  
  .whatsapp-icon {
    border-radius: 20px;
  }
  </style>