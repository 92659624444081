/* global axios */

import ApiClient from '../ApiClient';

/**
 * Represents the data object for a OpenAI hook.
 * @typedef {Object} ConversationMessageData
 * @property {string} [tone] - The tone of the message.
 * @property {string} [content] - The content of the message.
 * @property {string} [conversation_display_id] - The display ID of the conversation (optional).
 */

/**
 * A client for the OpenAI API.
 * @extends ApiClient
 */
class OpenAIAPI extends ApiClient {
  /**
   * Creates a new OpenAIAPI instance.
   */
  constructor() {
    super('integrations', { accountScoped: true });

    /**
     * The conversation events supported by the API.
     * @type {string[]}
     */
    this.conversation_events = [
      'summarize',
      'reply_suggestion',
      'label_suggestion',
      'taxi_request',
      'taxi_confirmation',
      'classification',
      'transport_info',
      'booking_request',
      'booking_request_json',
    ];

    /**
     * The message events supported by the API.
     * @type {string[]}
     */
    this.message_events = [
      'rephrase',
      'reply_suggestion',
      'label_suggestion',
      'taxi_request',
      'taxi_confirmation',
      'classification',
      'transport_info',
      'booking_request',
      'booking_request_json',
    ];
  }

  /**
   * Processes an event using the OpenAI API.
   * @param {Object} options - The options for the event.
   * @param {string} [options.type='rephrase'] - The type of event to process.
   * @param {string} [options.content] - The content of the event.
   * @param {string} [options.tone] - The tone of the event.
   * @param {string} [options.conversationId] - The ID of the conversation to process the event for.
   * @param {string} options.hookId - The ID of the hook to use for processing the event.
   * @returns {Promise} A promise that resolves with the result of the event processing.
   */
  processEvent({ type = 'rephrase', content, tone, conversationId, hookId }) {
    // Verifica che conversationId sia presente
    if (!conversationId) {
      console.error('conversationId è richiesto');
      throw new Error('conversationId è richiesto');
    }

    let eventData = {
      conversation_id: conversationId,  // Modificato da conversation_display_id a conversation_id
      content: content
    };

    console.log('Invio richiesta con dati:', {
      type,
      hookId,
      eventData
    });

    return axios.post(`${this.url}/hooks/${hookId}/process_event`, {
      event: {
        name: type,
        data: eventData
      }
    });
  }

  /**
   * Processa un suggerimento dell'utente utilizzando l'API OpenAI.
   * @param {Object} options - Le opzioni per l'elaborazione del suggerimento.
   * @param {string} options.hookId - L'ID del hook da utilizzare per l'elaborazione del suggerimento.
   * @param {string} options.suggestion - Il suggerimento dell'utente.
   * @param {string} options.originalDraft - La bozza originale generata dall'AI.
   * @param {string} options.conversationId - L'ID della conversazione associata.
   * @returns {Promise} Una promise che si risolve con il risultato dell'elaborazione del suggerimento.
   */
  processSuggestion({ hookId, suggestion, originalDraft, conversationId }) {
    return axios.post(`${this.url}/hooks/${hookId}/process_suggestion`, {
      event: {
        name: 'process_suggestion',
        data: {
          suggestion,
          original_draft: originalDraft,
          conversation_id: conversationId,  // Modificato da conversation_display_id a conversation_id
        },
      },
    });
  }
}

export default new OpenAIAPI();