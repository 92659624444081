import Vue from 'vue';
import ConversationApi from '../../api/inbox/conversation';
import types from '../mutation-types';

const state = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
  unreadCount: 0,
  unreadCountByInbox: {},
};

export const getters = {
  getStats: state => state,
  getUnreadCountByInbox: state => inboxId => {
    // console.log(
    //   'Stats: Getter getUnreadCountByInbox chiamato per inbox:',
    //   inboxId
    // );
    // console.log(
    //   'Stats: Stato corrente unreadCountByInbox:',
    //   state.unreadCountByInbox
    // );

    if (!inboxId) {
      return state.unreadCount;
    }

    const count = state.unreadCountByInbox[inboxId] || 0;
    // console.log('Stats: unreadCountByInbox for the current inbox:', count);
    return count;
  },
};

export const actions = {
  get: async ({ commit }, params) => {
    try {
      // console.log('CHIAMATA GET Stats: Action chiamata con params:', params);
      const response = await ConversationApi.meta(params);
      // console.log('CHIAMATA GET: Stats: Risposta API:', response);

      if (!response.data || !response.data.meta) {
        console.error('Stats: Dati mancanti nella risposta');
        return null;
      }

      const {
        data: { meta },
      } = response;

      // console.log('CHIAMATA GET Stats: Meta ricevuto:', meta);

      commit(types.UPDATE_CONVERSATION_STATS, {
        ...meta,
        inboxId: params.inboxId,
      });

      return meta;
    } catch (error) {
      console.error('Stats: Errore nel fetch:', error);
      return null;
    }
  },
};

export const mutations = {
  
  [types.UPDATE_CONVERSATION_STATS](state, meta) {
    // console.log('UPDATE_CONVERSATION_STATS init', meta);
    if (!meta || typeof meta !== 'object') {
      console.error('UPDATE_CONVERSATION_STATS: dati non validi:', meta);
      return;
    }

    const updates = {
      mineCount: parseInt(meta.mine_count || 0, 10),
      unAssignedCount: parseInt(meta.unassigned_count || 0, 10),
      allCount: parseInt(meta.all_count || 0, 10),
      unreadCount: meta.unread_count
        ? parseInt(meta.unread_count, 10)
        : state.unreadCount || 0,
      unreadCountByInbox: meta.unread_count_by_inbox
        ? meta.unread_count_by_inbox
        : state.unreadCountByInbox || {},
    };

    Object.entries(updates).forEach(([key, value]) => {
      Vue.set(state, key, value);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
