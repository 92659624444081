import { BLOCK_DIMENSIONS, calculateColumnWidth, getGridColumnSpan } from './gridConfig';

export const BLOCK_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  BUTTON: 'button',
  SPACER: 'spacer',
  DIVIDER: 'divider',
  ROW: 'row',
  COLUMN: 'column'
};

export const AVAILABLE_BLOCKS = [
  {
    type: 'text',
    label: 'Testo',
    icon: 'text_layout-outline',
    section: 'content',
    defaultContent: {
      text: '',
      styles: {
        textAlign: 'left',
        fontFamily: 'Arial',
        fontSize: '14px',
      }
    }
  },
  {
    type: 'image',
    label: 'Immagine',
    icon: 'image_layout-outline',
    section: 'content',
    defaultContent: {
      url: '',
      alt: '',
      styles: {
        width: '100%',
        alignment: 'center'
      }
    }
  },
  {
    type: 'button',
    label: 'Pulsante',
    icon: 'button_layout-outline',
    section: 'content',
    defaultContent: {
      text: 'Inserisci il testo',
      url: '#',
      styles: {
        backgroundColor: '#D9D9D9',
        color: '#262626',
        padding: '10px 20px',
        borderRadius: '4px',
        border: 'none',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        margin: '10px 0'
      }
    }
  },
  {
    type: 'spacer',
    label: 'Spazio',
    icon: 'arrows-v-outline',
    section: 'layout',
    defaultContent: {
      styles: {
        height: '20px',
        width: '100%'
      }
    }
  },
  {
    type: 'divider',
    label: 'Divisore',
    icon: 'minus-outline',
    section: 'layout',
    defaultContent: {
      style: 'solid',
      thickness: 1,
      color: '#e0e0e0'
    }
  },
  {
    type: 'row',
    label: 'Layout a Colonne',
    icon: 'grid-outline',
    section: 'layout'
  }
];

export const BLOCK_CONFIGS = {
  text: {
    defaultContent: {
      text: ''
    },
    placeholder: 'Inizia a scrivere qui...',
    defaultStyles: {
      fontFamily: 'Arial',
      fontSize: '14px',
      color: '#000000',
      gridColumn: getGridColumnSpan(BLOCK_DIMENSIONS.text.defaultColumns),
      width: calculateColumnWidth(BLOCK_DIMENSIONS.text.defaultColumns)
    }
  },
  image: {
    defaultContent: {
      url: '',
      alt: '',
      aspectRatio: null,
      originalDimensions: {
        width: null,
        height: null
      }
    },
    defaultStyles: {
      width: '100%',
      maxWidth: '600px',
      gridColumn: getGridColumnSpan(BLOCK_DIMENSIONS.image.defaultColumns),
      position: 'relative'
    },
    resizeConfig: {
      handles: ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'],
      grid: true,
      preserveAspectRatio: false,
      minWidth: calculateColumnWidth(BLOCK_DIMENSIONS.image.minColumns),
      maxWidth: calculateColumnWidth(BLOCK_DIMENSIONS.image.maxColumns)
    }
  },
  button: {
    defaultContent: {
      label: 'Nuovo Bottone',
      url: '#'
    },
    defaultStyles: {
      backgroundColor: '#D9D9D9',
      color: '#262626',
      padding: '10px 20px',
      borderRadius: '4px',
      border: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      display: 'inline-block',
      margin: '10px 0'
    },
    validation: {
      content: {
        text: { required: true, maxLength: 50 },
        url: { required: true, url: true }
      },
      styles: {
        backgroundColor: { required: true, pattern: /^#[0-9A-F]{6}$/i },
        color: { required: true, pattern: /^#[0-9A-F]{6}$/i },
        borderRadius: { required: true, pattern: /^\d+px$/ }
      }
    },
    editableProperties: {
      content: ['text', 'url'],
      styles: ['backgroundColor', 'color', 'padding', 'borderRadius', 'margin']
    },
    supportedEvents: {
      click: {
        label: 'Click',
        properties: ['url', 'target', 'rel']
      }
    }
  },

  [BLOCK_TYPES.SPACER]: {
    icon: 'icon-arrows-v',
    label: 'Spazio',
    category: 'layout',
    defaultContent: {},
    defaultStyles: {
      height: '20px',
      width: '100%'
    }
  },

  [BLOCK_TYPES.DIVIDER]: {
    icon: 'icon-minus',
    label: 'Divisore',
    category: 'layout',
    defaultContent: {
      style: 'solid',
      thickness: 1,
      color: '#e0e0e0'
    },
    defaultStyles: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px'
    }
  },

  row: {
    defaultContent: {
      columns: []
    },
    defaultStyles: {
      display: 'flex',
      gap: '20px',
      padding: '10px 0',
      width: '100%'
    }
  },

  column: {
    defaultContent: {
      blocks: []
    },
    defaultStyles: {
      width: '100%'
    }
  }
};

export const BLOCK_CATEGORIES = {
  basic: {
    label: 'Base',
    order: 1
  },
  media: {
    label: 'Media',
    order: 2
  },
  interactive: {
    label: 'Interattivo',
    order: 3
  },
  layout: {
    label: 'Layout',
    order: 4
  }
};