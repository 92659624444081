<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li
      class="font-medium h-7 my-1 hover:bg-slate-25 hover:text-bg-50 flex items-center px-2 rounded-md dark:hover:bg-slate-800"
      :class="{
        'bg-[#F2F3F5] dark:bg-slate-800': isActive,
        'text-ellipsis overflow-hidden whitespace-nowrap max-w-full':
          shouldTruncate,
      }"
      @click="navigate"
    >
      <a
        :href="href"
        class="inline-flex text-left max-w-full w-full items-center"
      >
        <span
          v-if="icon"
          class="inline-flex items-center justify-center w-4 rounded-sm dark:bg-slate-700 p-0.5 mr-1.5 rtl:mr-0 rtl:ml-1.5"
        >
          <fluent-icon
            class="text-xxs text-slate-700 dark:text-slate-200"
            :class="{
              'text-[#202020] dark:text-woot-500': isActive,
            }"
            :icon="icon"
            size="12"
          />
        </span>

        <span
          v-if="labelColor"
          class="inline-flex rounded-sm bg h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900"
          :style="{ backgroundColor: labelColor }"
        />
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
        >
          <span
            :title="menuTitle"
            class="text-sm text-slate-700 dark:text-slate-100"
            :class="{
              'text-[#202020] dark:text-woot-500': isActive,
              'text-ellipsis overflow-hidden whitespace-nowrap max-w-[60px]':
                shouldTruncate,
            }"
          >
            {{ label }}
          </span>
          <span
            v-if="showChildCount"
            class="bg dark:bg-slate-700 rounded-full min-w-[18px] justify-center items-center flex text-xxs font-medium mx-1 py-0 px-1"
            :class="
              isCountZero
                ? `text-slate-300 dark:text-slate-500`
                : `text-slate-700 dark:text-slate-50`
            "
          >
            {{ childItemCount }}
          </span>
        </div>
        <span
          v-if="warningIcon"
          class="inline-flex bg dark:bg p-0.5 rounded-sm"
          @click.stop.prevent="handleWarningClick"
        >
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.REAUTHORIZE')"
            class="text-xxs text-red-500 dark:text-red-300"
            :icon="warningIcon"
            size="16"
          />
        </span>
      </a>
      <div v-if="labelColor">
        <context-menu
          :context-menu-position="contextMenuPosition"
          :is-open="showContextMenu"
          :enabled-options="contextMenuEnabledOptions"
          :labelObject="labelObject"
          @open.stop.prevent="openContextMenu"
          @close="closeContextMenu"
          @edit-label="showEditLabelPopup"
        />
      </div>
    </li>
  </router-link>
</template>
<script>
import ContextMenu from 'dashboard/modules/labels/components/LabelContextMenu.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ContextMenu },
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
    labelObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isContextMenuOpen: false,
    contextMenuPosition: {},
    showContextMenu: false,
  }),
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    contextMenuEnabledOptions() {
      return {
        edit: true,
        delete: true,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
  },
  methods: {
    handleWarningClick() {
      this.$router.push(
        `/app/accounts/${this.accountId}/settings/inboxes/list`
      );
    },
    openContextMenu(e) {
      e.preventDefault();

      this.contextMenuPosition = {
        x: e.pageX || e.clientX,
        y: e.pageY || e.clientY,
      };
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.showContextMenu = false;
      this.contextMenuPosition = { x: null, y: null };
    },
    showEditLabelPopup() {
      this.$emit('sub-child-edit-label', { labelObject: this.labelObject });
    },
  },
};
</script>
