<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.BOOKING_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.BOOKING_CHANNEL.DESC')"
    />

    <!-- Aggiungi questa sezione per la spiegazione della generazione della chiave API -->
    <div class="mb-6">
      <h3 class="text-lg font-semibold mb-2">
        {{ $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.TITLE') }}
      </h3>
      <ol class="list-decimal list-inside">
        <li
          v-html="
            $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.STEPS.STEP1')
          "
        ></li>

        <p class="mt-2">
          <strong
            >{{
              $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.NOTE')
            }}:</strong
          >
        </p>
        <p>
          {{
            $t(
              'INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.ACCESS_ISSUES.TITLE'
            )
          }}
        </p>
        <ul class="list-disc list-inside ml-4">
          <li
            v-html="
              $t(
                'INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.ACCESS_ISSUES.REASON1'
              )
            "
          ></li>
          <li
            v-html="
              $t(
                'INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.ACCESS_ISSUES.REASON2'
              )
            "
          ></li>
        </ul>
        <p class="mt-2">
          {{
            $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.CONCLUSION')
          }}
        </p>

        <li
          v-html="
            $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.STEPS.STEP2')
          "
        ></li>
        <li
          v-html="
            $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API_KEY_GENERATION.STEPS.STEP3')
          "
        ></li>
      </ol>

      <!-- Aggiunta del link alla documentazione di Booking.com -->
      <p class="mt-4">
        <a
          href="https://developers.booking.com/demand/docs/development-guide/authentication#generate-an-api-key"
          target="_blank"
          rel="noopener noreferrer"
          class="text-blue-500 hover:text-blue-700 underline"
        >
          Documentation Link
        </a>
      </p>
    </div>

    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.NAME.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.BOOKING_CHANNEL.NAME.PLACEHOLDER')"
          />
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.EMAIL.LABEL') }}
          <input
            v-model.trim="emailAddress"
            type="email"
            :placeholder="
              $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.EMAIL.PLACEHOLDER')
            "
          />
        </label>
      </div>

      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label>
          {{ $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.TOKEN.LABEL') }}
          <input
            v-model.trim="bookingToken"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.BOOKING_CHANNEL.TOKEN.PLACEHOLDER')
            "
          />
        </label>
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.BOOKING_CHANNEL.SUBMIT_BUTTON')"
          class="booking-submit-button"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      emailAddress: '',
      bookingToken: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    inboxName: { required },
    emailAddress: { required },
    bookingToken: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const bookingChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            name: this.inboxName,
            email_address: this.emailAddress,
            channel: {
              type: 'booking',
              token: this.bookingToken,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: bookingChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message ||
            this.$t('INBOX_MGMT.ADD.BOOKING_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style scoped>
.booking-submit-button {
  background-color: #464646 !important;
  transition: background-color 0.3s ease;
}

.booking-submit-button:hover {
  background-color: #737373 !important;
}
</style>
