import DOMPurify from 'dompurify';
import { GRID_CONFIG, calculateColumnWidth } from '../config/gridConfig';
import { BLOCK_CONFIGS } from '../config/blockTypes';

const EMAIL_CONSTANTS = {
  containerWidth: GRID_CONFIG.containerWidth,
  columnWidth: GRID_CONFIG.containerWidth / GRID_CONFIG.columns, // 50px per colonna
  gutterWidth: GRID_CONFIG.gutterWidth,
};

const EMAIL_SAFE_STYLES = {
  // Font styles
  'font-family': ['Arial', 'Helvetica', 'Times New Roman', 'Georgia', 'Verdana'],
  'font-size': value => /^(1[0-4]|[1-9])\d*px$/.test(value),
  'color': value => /^#[0-9A-F]{6}$/i.test(value),
  'background-color': value => /^#[0-9A-F]{6}$/i.test(value),
  'text-align': ['left', 'center', 'right'],
  'line-height': value => /^(1[0-4]|[1-9])\d*px$/.test(value),
  
  // Spacing
  'padding': value => /^[0-9]+px( [0-9]+px){0,3}$/.test(value),
  'padding-top': value => /^[0-9]+px$/.test(value),
  'padding-right': value => /^[0-9]+px$/.test(value),
  'padding-bottom': value => /^[0-9]+px$/.test(value),
  'padding-left': value => /^[0-9]+px$/.test(value),
  
  // Borders
  'border': value => /^[0-9]+px solid #[0-9A-F]{6}$/i.test(value),
  'border-radius': value => /^[0-9]+px$/.test(value),
  'white-space': ['normal', 'nowrap'],
  'vertical-align': ['top', 'middle', 'bottom'],
  'word-break': ['normal', 'break-word'],
  'mso-line-height-rule': ['exactly'] // Per Outlook
}; 

export default {
  methods: {
    convertBlocksToHtml(blocks, layoutSettings = {}) {
      if (!Array.isArray(blocks)) {
        console.warn('convertBlocksToHtml: blocks deve essere un array');
        return '';
      }
      
      const backgroundColor = layoutSettings?.backgroundColor || '#ffffff';
      
      // Wrapper table principale
      const mainTable = `
        <table width="${EMAIL_CONSTANTS.containerWidth}" cellpadding="0" cellspacing="0" border="0" style="margin:0 auto; background-color: ${backgroundColor};">
          <tr>
            <td>
              ${blocks.map(block => this.convertBlockToHtml(block)).join('')}
            </td>
          </tr>
        </table>
      `;

      const emailWrapper = `
        <!--[if mso]>
        <table role="presentation" width="${EMAIL_CONSTANTS.containerWidth}" cellpadding="0" cellspacing="0" border="0" style="background-color: ${backgroundColor};">
        <tr><td><![endif]-->
        ${mainTable}
        <!--[if mso]></td></tr></table><![endif]-->
      `;
      return DOMPurify.sanitize(emailWrapper);
    },

    convertBlockToHtml(block) {
      switch (block.type) {
        case 'text':
          return this.convertTextBlock(block);
        case 'image':
          return this.convertImageBlock(block);
        case 'button':
          return this.convertButtonBlock(block);
        case 'spacer':
          return this.convertSpacerBlock(block);
        case 'divider':
          return this.convertDividerBlock(block);
        case 'row':
          return this.convertRowBlock(block);
        default:
          return '';
      }
    },

    convertTextBlock(block) {
      const width = calculateColumnWidth(block.gridData?.columns || BLOCK_CONFIGS.BLOCK_DIMENSIONS.text.defaultColumns);
      const safeText = DOMPurify.sanitize(block.content.text || '');
      const styles = this.sanitizeStyles({
        ...block.styles,
        width,
        'font-family': block.styles.fontFamily || 'Arial',
        'font-size': block.styles.fontSize || '14px',
        color: block.styles.color || '#000000'
      });

      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td width="${width}" style="${styles}">
              ${safeText}
            </td>
          </tr>
        </table>
      `;
    },

    convertImageBlock(block) {
      const width = calculateColumnWidth(block.gridData?.columns || BLOCK_CONFIGS.BLOCK_DIMENSIONS.image.defaultColumns);
      const styles = this.sanitizeStyles({
        width,
        'text-align': block.styles.alignment || 'center'
      });

      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td width="${width}" style="${styles}">
              <img 
                src="${block.content.url}" 
                alt="${block.content.alt || ''}" 
                width="${block.content.dimensions?.width || '100%'}"
                style="display: block; max-width: 100%;"
              />
            </td>
          </tr>
        </table>
      `;
    },

    convertButtonBlock(block) {
      const width = calculateColumnWidth(block.gridData?.columns || BLOCK_CONFIGS.BLOCK_DIMENSIONS.button.defaultColumns);
      const buttonStyles = this.sanitizeStyles({
        'background-color': block.styles.backgroundColor || '#000000',
        'color': block.styles.color || '#ffffff',
        'padding': block.styles.padding || '10px 20px',
        'border-radius': block.styles.borderRadius || '4px',
        'text-decoration': 'none',
        'display': 'inline-block',
        'text-align': 'center',
        'font-family': 'Arial, sans-serif'
      });

      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td width="${width}" align="center" style="padding: 10px 0;">
              <table cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td style="${buttonStyles}">
                    <a href="${block.content.url || '#'}" style="color: ${block.styles.color || '#ffffff'}; text-decoration: none;">
                      ${block.content.text || 'Click here'}
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      `;
    },

    convertSpacerBlock(block) {
      const height = block.styles.height || '20px';
      
      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td style="height: ${height}; font-size: 0; line-height: 0;">
              &nbsp;
            </td>
          </tr>
        </table>
      `;
    },

    convertDividerBlock(block) {
      const styles = this.sanitizeStyles({
        'border-top': `${block.content.thickness || 1}px ${block.content.style || 'solid'} ${block.content.color || '#e0e0e0'}`,
        'width': '100%',
        'margin': '10px 0'
      });

      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td style="${styles}">
              &nbsp;
            </td>
          </tr>
        </table>
      `;
    },

    convertRowBlock(block) {
      // Versione base per ora
      return `
        <table width="100%" cellpadding="0" cellspacing="0" border="0">
          <tr>
            <td style="padding: ${EMAIL_CONSTANTS.gutterWidth}px 0;">
              <table width="100%" cellpadding="0" cellspacing="0" border="0">
                <tr>
                  ${block.content.columns?.map(column => `
                    <td width="${calculateColumnWidth(column.width || 1)}">
                      ${column.blocks?.map(childBlock => 
                        this.convertBlockToHtml(childBlock)
                      ).join('') || ''}
                    </td>
                  `).join('') || ''}
                </tr>
              </table>
            </td>
          </tr>
        </table>
      `;
    },

    sanitizeStyles(styles) {
      const safeStyles = {};
      
      Object.entries(styles).forEach(([key, value]) => {
        if (EMAIL_SAFE_STYLES[key]) {
          if (Array.isArray(EMAIL_SAFE_STYLES[key])) {
            // Per valori enumerati (come font-family)
            if (EMAIL_SAFE_STYLES[key].includes(value)) {
              safeStyles[key] = value;
            }
          } else if (typeof EMAIL_SAFE_STYLES[key] === 'function') {
            // Per validazioni con regex
            if (EMAIL_SAFE_STYLES[key](value)) {
              safeStyles[key] = value;
            }
          }
        }
      });

      return Object.entries(safeStyles)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ');
    }
  },
}; 