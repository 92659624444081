var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"context-menu"},[_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteConfirmationPopup,"on-close":_vm.closeDeletePopup,"on-confirm":_vm.confirmDeletion,"title":_vm.$t('LABEL_MGMT.DELETE.CONFIRM.TITLE'),"message":_vm.$t('LABEL_MGMT.DELETE.CONFIRM.MESSAGE'),"message-value":_vm.deleteMessage,"confirm-text":_vm.deleteConfirmText,"reject-text":_vm.deleteRejectText},on:{"update:show":function($event){_vm.showDeleteConfirmationPopup=$event}}}),_vm._v(" "),_c('woot-button',{attrs:{"icon":"more-vertical","color-scheme":"secondary","variant":"clear","size":"small"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleOpen.apply(null, arguments)}}}),_vm._v(" "),(_vm.isOpen)?_c('woot-context-menu',{attrs:{"x":_vm.contextMenuPosition.x,"y":_vm.contextMenuPosition.y},on:{"close":_vm.handleClose}},[_c('div',{staticClass:"menu-container"},[(_vm.enabledOptions['create'])?_c('menu-item',{attrs:{"option":{
          icon: 'add-circle',
          label: _vm.$t('Crea sotto-etichetta'),
        },"color-scheme":"success","class-names":"button--fixed-top","variant":"icon"},on:{"click":_vm.openAddPopup}}):_vm._e(),_vm._v(" "),(_vm.enabledOptions['edit'])?_c('menu-item',{attrs:{"option":{
          icon: 'edit',
          label: _vm.$t('Modifica'),
        },"color-scheme":"secondary","class-names":"grey-btn","variant":"icon"},on:{"click":_vm.openEditPopup}}):_vm._e(),_vm._v(" "),(_vm.enabledOptions['delete'])?_c('menu-item',{attrs:{"option":{
          icon: 'dismiss-circle',
          label: _vm.$t('Elimina'),
        },"color-scheme":"alert","class-names":"grey-btn","variant":"icon"},on:{"click":_vm.openDeletePopup}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }