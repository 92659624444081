
import * as types from '../mutation-types';
import HotelAPI from '../../api/hotels';

const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  hotelsByAccount: {},
};

const getters = {
  getHotelForAccount: (state) => (accountId) => state.hotelsByAccount[accountId],
};

const actions = {
  
  async fetchHotelForAccount({ commit }, accountId) {
    try {
      const response = await HotelAPI.getForAccount(accountId);
      commit(types.default.SET_HOTEL_FOR_ACCOUNT, { accountId, data: response.data });
    } catch (error) {
      // Handle error
      console.error('Error fetching hotel data:', error);
    }
  },
};

const mutations = {
  [types.default.SET_HOTEL_FOR_ACCOUNT](state, { accountId, data }) {
    state.hotelsByAccount = {
      ...state.hotelsByAccount,
      [accountId]: data,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};