// sharedState.js
const state = {
  replyBoxMessage: '',
  bookingData: null,
};

const mutations = {
  SET_REPLY_BOX_MESSAGE(state, message) {
    state.replyBoxMessage = message;
  },

  SET_BOOKING_DATA(state, data) {
    state.bookingData = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
